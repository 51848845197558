<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNumbersGamesStore } from '~/stores/numbersGames'
import { useCurrentRoleStore } from '~/store/currentRole'

const numbersGameStore = useNumbersGamesStore()
const currentRoleStore = useCurrentRoleStore()
const { currentRole } = storeToRefs(currentRoleStore)
const route = useRoute()

const drawerNavWithRoles = computed(() => {
  return [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'i-heroicons-adjustments-horizontal',
      color: 'text-purple-700',
      to: `/dashboard/${currentRole.value.role}`,
    },
    {
      id: 'classes',
      label: 'Classes',
      icon: 'i-heroicons-academic-cap-solid',
      color: 'text-amber-500',
      to: '/classes',
    },
    // {
    //   id: 'students',
    //   label: 'Students',
    //   icon: 'i-heroicons-user-group-solid',
    //   color: 'text-blue-700',
    //   to: '/classes/dashboard/admin',
    // },
    // {
    //   id: 'teachers',
    //   label: 'Teachers',
    //   icon: 'i-heroicons-academic-cap-solid',
    //   color: 'text-orange-700',
    //   to: '/classes/dashboard/admin',
    // },
    // {
    //   id: 'insights',
    //   label: 'Insights',
    //   icon: 'i-heroicons-academic-cap-solid',
    //   color: 'text-green-700',
    //   to: '/dashboard/admin/insights',
    // },
  ]
})

const drawerNavWithoutRoles = [
  {
    id: 'home',
    label: 'Home',
    icon: 'i-heroicons-home',
    color: 'text-purple-700',
    to: '/',
  },
  {
    id: 'classes',
    label: 'Classes',
    icon: 'i-heroicons-academic-cap-solid',
    color: 'text-amber-500',
    to: '/classes',
  },
]

const getNavigation = () => {
  if (currentRole.value.role) {
    return drawerNavWithRoles.value
  } else {
    return drawerNavWithoutRoles
  }
}
const drawerNavigation = ref(drawerNavWithoutRoles)

const supabase = useSupabaseClient()
const isOpen = ref(false)
const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) {
    useBugsnag().notify(error)
    console.log(error)
  }
  // @todo reset all game scores in zero with one function
  numbersGameStore.setAllgameProgressToZero()
  isOpen.value = false
  navigateTo('/login')
}

const user = useSupabaseUser()
const schoolRoles = useGetUserSchools()

const { setCurrentRole } = useCurrentRoleStore()

const setUserRole = (schoolRole: SchoolRole) => {
  if (!schoolRole) return
  setCurrentRole(schoolRole)
}

onMounted(() => {
  if (schoolRoles.length === 1) {
    setUserRole(schoolRoles[0])
    return
  }
  drawerNavigation.value = getNavigation()
  if (
    user.value &&
    !currentRole.value.role &&
    route.path.includes('dashboard')
  ) {
    navigateTo(`/dashboard`)
  }
})
watch(
  () => currentRole.value,
  () => {
    drawerNavigation.value = getNavigation()
  },
)

const links = ref(useBreadcrumbItems())
const isDashboard = computed(() =>
  links.value.some((link) => link.to?.includes('dashboard')),
)
const breadcrumbLinks = computed(() => {
  const isDashboard = links.value.some((link) => link.to === '/dashboard')
  if (isDashboard) {
    const dashboardUrl =
      currentRole.value.role && currentRole.value.schoolId
        ? `/dashboard/${currentRole.value.role}`
        : '/dashboard'
    return [
      {
        label: 'Home',
        to: '/',
      },
      {
        label: 'Dashboard',
        to: dashboardUrl,
      },
      ...(links.value.length > 4 ? [links.value[links.value.length - 1]] : []),
    ]
  }
  return links.value
})
</script>
<template>
  <div>
    <nav
      class="grid grid-cols-2 md:grid-cols-3 shadow-md shadow-gray-200 w-full"
    >
      <div class="flex items-center">
        <UIcon
          name="i-heroicons-bars-3"
          class="h-6 w-6 ml-4 cursor-pointer"
          @click="isOpen = true"
        />
      </div>
      <div class="hidden md:flex justify-center items-center">
        <span
          v-show="user?.user_metadata?.name"
          class="font-semibold text-lg text-primary text-center"
          >Welcome in<br />
          {{ currentRole?.schoolName }}</span
        >
      </div>
      <div class="flex flex-wrap items-center justify-end p-4">
        <NuxtLink
          v-if="user?.email"
          to="/dashboard"
          class="no-underline hidden md:flex items-center"
        >
          {{ user?.user_metadata.name }}
          <UIcon
            name="i-heroicons-user-circle"
            class="h-6 w-6 ml-4 cursor-pointer"
          />
        </NuxtLink>
        <NuxtLink v-show="!user?.email" to="/login" class="flex items-center">
          <UIcon
            name="i-heroicons-user-circle"
            class="h-6 w-6 ml-4 cursor-pointer"
          />
        </NuxtLink>
      </div>
    </nav>
    <UBreadcrumb
      v-show="breadcrumbLinks.length > 1"
      class="ml-4 pt-4"
      :class="{ 'bg-gray-50': isDashboard }"
      :links="breadcrumbLinks"
      :ui="{ ol: 'gap-x-3', li: 'gap-x-3 md:text-lg' }"
    >
      <template #divider>
        <span class="w-8 h-1 rounded-full bg-gray-300 dark:bg-gray-700" />
      </template>
    </UBreadcrumb>
    <slot />
    <USlideover v-model="isOpen" side="left">
      <UCard
        class="flex flex-col flex-1"
        :ui="{
          body: { base: 'flex-1' },
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          <NuxtLink to="/">
            <img src="/WLearn_Logo_Blue.svg" class="h-12 pt-2 pl-4" />
          </NuxtLink>
          <hr class="mt-4" />
          <ul>
            <li
              v-for="(item, index) in drawerNavigation"
              :key="`nav-item-${index}`"
              class="mt-4"
              :class="item.color"
            >
              <NuxtLink
                class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white rounded-l-lg"
                :to="item.to"
                @click="isOpen = false"
              >
                <div class="flex">
                  <UIcon :name="item.icon" class="h-8 w-8 mx-4" />
                  <span class="font-bold text-xl">
                    {{ item.label }}
                  </span>
                </div>
              </NuxtLink>
            </li>
          </ul>
        </template>
        <template #footer>
          <hr />
          <NuxtLink
            v-show="user"
            class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white cursor-pointer rounded-l-lg"
            @click="signOut"
          >
            <div class="flex text-blue-600 hover:text-white">
              <UIcon
                name="i-heroicons-arrow-right-on-rectangle-solid"
                class="h-8 w-8 mx-4"
              />
              <span class="font-bold text-xl"> Sign out </span>
            </div>
          </NuxtLink>
        </template>
      </UCard>
    </USlideover>
  </div>
</template>
